import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.png'
import _imports_1 from '../assets/books.jpg'


const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      alt: "Vue logo",
      src: _imports_0
    }, null, -1),
    _createElementVNode("img", {
      alt: "Vue logo",
      src: _imports_1
    }, null, -1)
  ])))
}